@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@keyframes pulse {
  0%, 100% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.2);
    opacity: .5;
  }
}

.pulse-animation {
  animation: pulse 2s infinite;
}

@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

/* Tilpasser slides slik at de ikke strekker kortene */
.slick-slide > div {
  display: flex;
  justify-content: center; /* Senterer kortet i sliden */
}

/* Definerer en maksimal bredde og sentrering for RecipeCard */
.recipe-card {
  max-width: 300px; /* Eller bredden på dine kort */
  margin: 0 auto; /* Senterer kortet hvis sliden er bredere enn kortet */
}